import { utils } from './utils';

const seeAll = {
  btns: document.querySelectorAll(`.js-toggle-all`),
  openClass: `is-open`,
  openAttribute: `data-open`,

  toggle(e) {
    const el = e.currentTarget;
    const id = el.getAttribute(`data-id`);
    const openText = el.getAttribute(`data-open-text`);
    const closedText = el.getAttribute(`data-closed-text`);
    const parent = document.getElementById(id);

    e.preventDefault();
    parent.classList.toggle(this.openClass);

    if (el.hasAttribute(this.openAttribute)) {
      el.removeAttribute(this.openAttribute);
      el.textContent = openText;
    } else {
      el.setAttribute(this.openAttribute, true);
      el.textContent = closedText;
    }
  },

  init() {
    utils.forEach(this.btns, (index, btn) => {
      btn.addEventListener(`click`, this.toggle.bind(this));
    });
  }
};

export { seeAll };
