import gsap from 'gsap';

function show(copyContainer, container) {
  container.classList.add('accordion-is-open');
  const tl = gsap.timeline();
  tl.set(copyContainer, {
    height: 'auto',	
    visibility: 'visible'
  })
  .from(copyContainer, {
    height: 0,
    duration: 0.2,
    ease: 'power2.out',
    immediateRender: false
  });
}

function hide(copyContainer, container) {
  container.classList.remove('accordion-is-open');
  gsap.to(copyContainer, {
    height: 0,
    duration: 0.1,
    ease: 'none',	
  });
}

function toggle(button) {
  const container = button.closest(".js-accordion-container");
  const copyContainer = container.querySelector(".js-accordion-content");
  if (container.classList.contains('accordion-is-open')) {
    hide(copyContainer, container);
  } else {
    show(copyContainer, container);
  } 
}

export default function() {
  document.addEventListener("click", function(e) {
    const clicked = e.target;
    const button = clicked.closest('.js-accordion-button');
    if (button) {
      toggle(button);
    } 
  });
}