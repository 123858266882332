import { TweenMax, TimelineMax } from 'gsap';
// Separate import for css-plugin not working for tweenlite. Weird.
import { menu } from './menu';

const modal = document.querySelector('#donate-modal');
const container = document.querySelector('#donate-container');
const showBtns = Array.from(document.querySelectorAll('.js-show-donate'));
const hideBtn = document.querySelector('#hide-donate');
const giftYesBtn = document.querySelector('#enupalStripe-giftAid-1');
const giftNoBtn = document.querySelector('#enupalStripe-giftAid-2');
const firstname = document.querySelector('#enupalStripe-firstname');
const lastname = document.querySelector('#enupalStripe-lastname');
const address = document.querySelector('#enupalStripe-address');
const postcode = document.querySelector('#enupalStripe-postcode');
const fields = [firstname, lastname, address, postcode].filter(item => item !== null).map(item => {
  return item.closest('.fields');
});
TweenMax.set(fields, {display: 'none'});

function showModal() {
  document.body.classList.add('donate-modal-active');
  menu.closeMenu();
  const tl = new TimelineMax();
  tl.set(modal, {
    display: 'flex',
    height: 'auto'
  })
  .fromTo(modal, 0.1, {
    opacity: 0,
  }, {
    opacity: 1
  })
  .fromTo(container, 0.2, {
    opacity: 0,
    y: 20,
  }, {
    opacity: 1,
    y: 0,
    ease: 'Back.easeOut.config(1.7)'
  }, "-=0.1");
}

function hideModal() {
  document.body.classList.remove('donate-modal-active');
  TweenMax.to(modal, 0.1, {
    opacity: 0,
    display: 'none',
    ease: 'Power0.easeNone'
  })
  TweenMax.set(container, {
    height: 'auto'
  })
}

function toggleFields(e) {
  const clicked = e.currentTarget;
  const val = clicked.value;
  if (val === 'true') {
    TweenMax.set(fields, {display: 'block'});
  } else {
    TweenMax.set(fields, {display: 'none'});
  }
}

export default function() {
  if (!modal) {
    return;
  }

  showBtns.forEach(btn => {
    btn.addEventListener('click', showModal);
  });
  hideBtn.addEventListener('click', hideModal);

  if (giftYesBtn) {
    giftYesBtn.addEventListener('click', toggleFields);
  }

  if (giftNoBtn) {
    giftNoBtn.addEventListener('click', toggleFields);
  }
}
