import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

import timelines from './modules/timelines';
import chart from './modules/chart';
import stat from './modules/stat';
import homeVimeo from './modules/homeVimeo';
import impactHero from './modules/impact-hero';
import { share } from './modules/share';
import { seeAll } from './modules/seeAll';
import { stickyNav } from './modules/stickyNav';
import { menu } from './modules/menu';
import { search } from './modules/search';
import { rfw } from './modules/rfw';
import { backgroundVideo } from './modules/backgroundVideo';
import { modal } from './modules/modal';
import { form } from './modules/form';
import { ajaxNav } from './modules/ajax-navigation';
import { triathlonData } from './modules/triathlonData';
import donate from './modules/donate';
import impactNav from './modules/impact-nav';
import popup from './modules/popup';
import timer from './modules/timer';
import accordion from './modules/accordion';
import carousel from './modules/carousel';
// import { forEach } from 'lodash';

homeVimeo();
share.init();
seeAll.init();
stickyNav.init();
menu.init();
search.init();
rfw.init();
backgroundVideo.init();
modal.init();
form.init();
ajaxNav.init();
triathlonData.init();
donate();
chart();
stat();
impactHero();
popup();
accordion();
carousel();

window.history.scrollRestoration = "manual";
window.addEventListener("load", function() {
  timer();
  timelines();
  impactNav();
});

// if tab key is used, add class to show outline styles
function handleFirstTab(e) {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing');
    window.removeEventListener('keydown', handleFirstTab);
  }
}

window.addEventListener('keydown', handleFirstTab);

// Listen for resize changes to the content container - e.g. in case of an embedded iframe.
const resizeObserver = new ResizeObserver((entries) => {
  for (const entry of entries) {
    ScrollTrigger.refresh();
  }
});

const container = document.querySelector(".content");
if (container) {
  resizeObserver.observe(container);
}
