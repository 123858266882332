import { TweenLite } from 'gsap';
import { utils } from './utils';

const menu = {
  toggle: document.querySelector(`.js-nav-toggle`),
  subToggles: document.querySelectorAll(`.js-menu-toggle`),
  item: document.querySelectorAll(`.js-menu-item`),
  subMenu: document.querySelectorAll(`.js-menu-sub`),
  itemOpenClass: `is-open`,
  tweenDuration: 0.2,
  navToggleWidth: 960,

  closeMenu() {
    const body = document.body;
    const menuOpenClass = `menu-is-open`;

    if (body.classList.contains(menuOpenClass)) {
      // on menu close
      body.classList.remove(menuOpenClass);
      this.toggle.textContent = `Menu`;

      utils.forEach(this.item, (index, item) => {
        item.classList.remove(this.itemOpenClass);
      });

      utils.forEach(this.subMenu, (index, submenu) => {
        submenu.setAttribute(`style`, ``);
      });
    }
  },

  toggleMenu(e) {
    const body = document.body;
    const menuOpenClass = `menu-is-open`;

    e.preventDefault();

    if (body.classList.contains(menuOpenClass)) {
      // on menu close
      body.classList.remove(menuOpenClass);
      this.toggle.textContent = `Menu`;

      utils.forEach(this.item, (index, item) => {
        item.classList.remove(this.itemOpenClass);
      });

      utils.forEach(this.subMenu, (index, submenu) => {
        submenu.setAttribute(`style`, ``);
      });
    } else {
      // on menu open
      body.classList.add(menuOpenClass);
      this.toggle.textContent = `Close Menu`;
    }
  },

  closeSubMenu(el) {
    if (window.innerWidth < this.navToggleWidth) {
      TweenLite.to(el, this.tweenDuration, {
        height: 0,
        onComplete: () => {
          el.setAttribute(`style`, ``);
        }
      });
    }

    el.parentNode.classList.remove(this.itemOpenClass);
  },

  toggleSubMenu(e) {
    const el = e.currentTarget;
    const elParent = el.parentNode;
    const subMenu = el.nextElementSibling;

    e.preventDefault();

    if (elParent.classList.contains(this.itemOpenClass)) {
      this.closeSubMenu(subMenu);
    } else {
      if (window.innerWidth < this.navToggleWidth) {
        TweenLite.set(subMenu, { height: `auto` });
        TweenLite.from(subMenu, this.tweenDuration, { height: 0 });
      } else {
        utils.forEach(this.subMenu, (index, subMenu) => {
          this.closeSubMenu(subMenu);
        });
      }

      elParent.classList.add(this.itemOpenClass);
    }
  },

  init() {
    this.toggle.addEventListener(`click`, this.toggleMenu.bind(this));

    utils.forEach(this.subToggles, (index, subToggle) => {
      subToggle.addEventListener(`click`, this.toggleSubMenu.bind(this));
    });
  }
};

export { menu };
