import $ from 'jquery';
window.$ = $;
import { utils } from './utils';

const triathlonData = {
  toCommas(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },

  init() {
    const triathlon = document.querySelector(`.js-triathlon`);

    if (triathlon) {
      const appId = `7f518f24`;
      const charityName = `impetuspef`;
      const campaignName = `2019-Impetus-PEF-Triathlon`;
      // const apiUrl = `https://api.justgiving.com/${appId}/v1/campaigns/${charityName}/${campaignName}`;
      const apiUrl = `https://api.justgiving.com/${appId}/v1/event/5101564`;

      // $.ajax(apiUrl, {
      //   dataType: `json`,
      //   success: response => {
      //     console.log(response)
      //     // const raised = response.targetPercentage;
      //     // const total = this.toCommas(Math.ceil(parseFloat(response.totalRaised)));
      //     // const target = this.toCommas(response.target);
      //     //
      //     // $(`.js-triathlon-complete`).html(`${raised}% Complete`);
      //     // $(`.js-triathlon-progress`).css(`width`, `${raised}%`);
      //     // $(`.js-triathlon-raised`).html(`£${total}`);
      //     // $(`.js-triathlon-target`).html(`£${target}`);
      //   },
      //   complete: () => {
      //
      //   }
      // });
    }
  }
};

export { triathlonData };
