import '../plugins/noframework.waypoints.js';
import { utils } from './utils';

const rfw = {
  toggles: document.querySelectorAll(`.js-rfw-toggle`),

  init() {
    utils.forEach(this.toggles, (index, toggle) => {
      toggle.addEventListener(`click`, () => {
        toggle.previousElementSibling.classList.toggle(`is-parent`);
        toggle.parentNode.classList.toggle(`is-parent`);
        Waypoint.refreshAll();
      });
    });
  }
};

export { rfw };
