import { utils } from './utils';

const share = {
  links: document.querySelectorAll(`.js-share`),

  openShareWindow(e) {
    e.stopPropagation();
    e.preventDefault();

    const link = e.currentTarget;
    const url = link.getAttribute(`href`);
    const width = 500;
    const height = 300;
    const left = (window.innerWidth / 2) - (width / 2);
    const top = (window.innerHeight / 2) - (height / 2);

    e.preventDefault();

    window.open(
      url,
      '',
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,' +
      `width=${width},height=${height},top=${top},left=${left}`);
  },

  init() {
    utils.forEach(this.links, (index, link) => {
      link.addEventListener(`click`, this.openShareWindow);
    });
  }
};

export { share };
