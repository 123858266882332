import { debounce } from 'lodash';
import '../plugins/noframework.waypoints.js';

const stickyNav = {
  rfw: document.getElementById(`rfw`),
  nav: document.getElementById(`rfw-nav`),
  menu: document.getElementById(`rfw-menu`),
  breadcrumb: document.getElementById(`breadcrumb`),
  footer: document.getElementById(`footer`),
  topOffset: 10,

  rect(el) {
    return el.getBoundingClientRect();
  },

  fixToTop() {
    this.menu.style.position = `fixed`;
    this.menu.style.top = `${this.topOffset}vh`;
    this.menu.style.left = `${this.rect(this.nav).left}px`;
    this.menu.style.width = `${this.nav.offsetWidth}px`;
  },

  fixToBottom() {
    this.menu.style.position = `absolute`;
    this.menu.style.top = `${this.rfw.clientHeight - this.menu.clientHeight - 15 - 15 - 7.5}px`;
    this.menu.style.left = `0`;
    this.menu.style.width = `${this.nav.offsetWidth}px`;
  },

  unFix() {
    this.menu.setAttribute(`style`, ``);
  },

  resize() {
    if (window.innerWidth < 768) {
      this.unFix();
    }
  },

  init() {
    if (!this.nav) {
      return false;
    }

    const topWaypoint = new Waypoint({
      element: this.nav,
      handler: direction => {
        if (window.innerWidth >= 768) {
          direction === `down` ? this.fixToTop() : this.unFix();
        }
      },
      offset: `${this.topOffset}%`,
    });

    const bottomWaypoint = new Waypoint({
      element: this.breadcrumb,
      handler: direction => {
        if (window.innerWidth >= 768) {
          direction === `down` ? this.fixToBottom() : this.fixToTop();
        }
      },
      offset: () => {
        return (0.1 * window.innerHeight) + this.menu.clientHeight + 7.5 + 15 + 60;
      },
    });

    window.addEventListener(`resize`, debounce(this.resize, 200).bind(this));
  }
};

export { stickyNav };
