// Uses GSAP carousel/loop helper function:
// https://gsap.com/docs/v3/HelperFunctions/helpers/seamlessLoop
import { horizontalLoop } from './horizontal-loop.js';
import gsap from 'gsap';

function initCarousel(carousel) {
  const items = gsap.utils.toArray(carousel.querySelectorAll('.js-carousel-item'));
  const buttons = carousel.querySelectorAll('.js-carousel-button');

  let carouselInstance;
  let interval;
  let activeElement;

  carouselInstance = horizontalLoop(items, {
    interval: () => {
      return interval;
    },
    paused: true,
    center: true,
    // repeat: 0,
    draggable: true,
    onChange: (element) => { // when the active element changes, this function gets called.
      activeElement && activeElement.classList.remove("carousel-item-active");
      element.classList.add("carousel-item-active");
      activeElement = element;
    }
  });
  // If CSS width isn't 100% divided by the number of items (e.g. item with is 60% of container) it won't center - this seems to fix it
  carouselInstance.toIndex(0, {duration: 0});

  interval = setInterval(() => {
    carouselInstance.next({duration: 0.5});
  }, 5000);

  buttons.forEach(button => {
    button.addEventListener('click', () => {
      const direction = button.dataset.direction;
      carouselInstance[direction]({duration: 0.5});
      clearInterval(interval);
    })
  });
}

export default function() {
  const carousels = document.querySelectorAll('.js-carousel');
  if (!carousels.length) return;
  carousels.forEach(carousel => initCarousel(carousel));
}