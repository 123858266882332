import Player from '@vimeo/player';

let video;
const container = document.getElementById('video-hero-container');

function init() {
  const url = container.dataset.url;
  const interactive = container.dataset.interactive;
  if (!url) {
    console.log('empty url');
    return;
  }

  video = new Player(container, {
    url: url,
    background: true,
    transparent: false,
    byline: false,
    title: false,
  });
}

export default function() {
  if (!container) {
    return;
  }
  init();
}

