import gsap from 'gsap';

const body = document.body;
const activeClass = 'popup-is-active';
const innerSelector = '.js-popup-inner';
const buttonSelector = '.js-popup-button';
const buttons = document.querySelectorAll(buttonSelector);
const closeButtons = document.querySelectorAll('.js-popup-close');

function show(e) {
  const button = e.target.closest(buttonSelector);
  if (!button) return;
  const popupSelector = button.dataset.popupId;
  const popup = document.getElementById(popupSelector);
  if (!popup) return;
  const inner = popup.querySelector(innerSelector);
  const tl = gsap.timeline();
  tl.add('start')
  tl.to(popup, { 
    display: 'block', 
    duration: 0.1
  }, 'start')
  .fromTo(inner, {
    opacity: 0,
    y: 10
  }, {
    opacity: 1,
    y: 0,
    duration: 0.1,
  }, 'start');

  popup.classList.add(activeClass);
}

function hide(e) {
  e.stopPropagation();
  const popup = document.querySelector(`.${activeClass}`);
  if (!popup) return;
  const inner = popup.querySelector(innerSelector);
  const tl = gsap.timeline();
  tl.add('start')
  tl.to(popup, { 
    display: 'none', 
    duration: 0.1
  }, 'start')
  .to(inner, {
    opacity: 0,
    y: 10,
    duration: 0.1,
  }, 'start');

  popup.classList.remove(activeClass);
}

function escHide(e) {
  const key = e.key || e.code;
  if (key === "Escape") {
    hide(e);
  }
}

export default function() {
  buttons.forEach(button => button.addEventListener('click', show));
  closeButtons.forEach(button => button.addEventListener('click', hide));

  document.addEventListener('click', function(e) {
    const inner = e.target.closest(innerSelector);
    const button = e.target.closest(buttonSelector);
    if (!inner && !button) {
      hide(e);
    }
  });

  window.addEventListener('keydown', escHide);
}