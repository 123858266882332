import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const activeClass = 'box-is-active';
const statSelect = '.js-stat-item';
const infoBoxSelect = '.js-info-box';
const stats = document.querySelectorAll(statSelect);
const closeBtns = document.querySelectorAll('.js-close-info-box');

function closeBox(e) {
  const target = e.currentTarget;
  const statItem = target.closest(statSelect);
  const infoBox = statItem.querySelector(infoBoxSelect);
  infoBox.classList.remove(activeClass);
}

function showBox(e) {
  const target = e.currentTarget;
  const statItem = target.closest(statSelect);
  const infoBox = statItem.querySelector(infoBoxSelect);
  infoBox.classList.add(activeClass);
}

function initInfoBoxes() {
  stats.forEach(function(stat) {
    const button = stat.querySelector('.js-stat-button');
    if (!button) return;
    button.addEventListener('click', showBox);
  });

  closeBtns.forEach(function(button) {
    button.addEventListener('click', closeBox);
  });
}

function initAnims() {
  ScrollTrigger.batch(stats, {
    once: true,
    onEnter: batch => {
      batch.forEach((stat, index) => {
        const statNumber = stat.querySelector('.js-stat-number');
        const delay = index * 0.2;
        let tl = gsap.timeline();
        tl.add('start');
        tl.from(stat, {
          opacity: 0,
          y: 10,
          duration: 0.5,
          delay: delay,
          onComplete: () => {
            gsap.set(stat, {clearProps: 'all'});
          }
        });
        tl.fromTo(statNumber, {
          innerText: 0,
        }, {
          delay: delay,
          innerText: parseInt(statNumber.innerText),
          duration: 5,
          modifiers: {
            innerText: function(innerText) {
              return  gsap.utils.snap(1, parseInt(innerText)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
          },
          ease: 'none'
        }, 'start');
        // tl.from(statNumber, {
        //   delay: delay,
        //   innerText: 0,
        //   duration: 5,
        //   modifiers: {
        //     innerText: function(innerText) {
        //       return  gsap.utils.snap(1, parseInt(innerText)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        //     }
        //   },
        //   ease: 'none'
        // }, 'start');
      });
    }
  });
}

export default function() {
  initAnims();
  initInfoBoxes();
}