import { utils } from './utils';
import { debounce } from 'lodash';

const backgroundVideo = {
  el: document.querySelector(`.js-background-video`),
  vid_w_orig: 1920,
  vid_h_orig: 890,

  resize() {
    // get the parent element size
    // const container_w = this.el.parentNode.offsetWidth;
    // const container_h = this.el.parentNode.offsetHeight;
    //
    // // use largest scale factor of horizontal/vertical
    // const scale_w = container_w / this.vid_w_orig;
    // const scale_h = container_h / this.vid_h_orig;
    // const scale = scale_w > scale_h ? scale_w : scale_h;
    //
    // // scale the video
    // this.el.style.width = `${scale * this.vid_w_orig}px`;
    // this.el.style.height = `${scale * this.vid_h_orig}px`;
  },

  init() {
    if (!this.el) {
      return false;
    }

    // window.addEventListener(`resize`, debounce(this.resize, 200).bind(this));

    utils.isAutoplaySupported(function(supported) {
      if (!supported) {
        const video = document.querySelector(`.js-background-video`);

        video.setAttribute(`poster`, `images/video-background2.jpg`);
      }
    });

    // this.resize();
    this.el.classList.add(`is-active`);
  }
};

export { backgroundVideo };
