import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

function initTimeline(el) {
  const section = el.closest('.js-timeline-parent');
  const heading = section.querySelector('.js-tl-heading');

  gsap.to(el, {
    xPercent: () => {
      const scrollWidth = el.scrollWidth;
      const offsetWidth = el.offsetWidth;
      const overflowPercent = ((scrollWidth - offsetWidth) / offsetWidth * 100).toFixed(2);
      return `-${overflowPercent}`;
    },
    duration: 1,
    scrollTrigger: {
      trigger: heading,
      start: 'top 20px',
      pin: section,
      scrub: true,
      end: () => '+=200%',
      invalidateOnRefresh: true,
      fastScrollEnd: true,
      preventOverlaps: true
    }
  })
}

export default function() {
  const containers = document.querySelectorAll('.js-timeline');
  containers.forEach(container => initTimeline(container));
}
