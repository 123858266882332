import $ from 'jquery';
import { utils } from './utils';
window.$ = $;

export const ajaxNav = {
  wrapperId: `tabs-ajax-wrapper`,
  tabs: document.querySelectorAll(`.js-tab`),
  loadingClass: `is-loading`,
  activeTabClass: `is-active`,
  changedPage: false,
  isLoading: false,
  activeSubTabs: null,

  updatePage(html) {
    const $html = $(`<div />`).append(html)
    const $content = $html.find(`#tabs-ajax-wrapper`).html();

    $(`#${this.wrapperId}`).html($content);

    this.addClickEvents();
  },

  toggleWrapperLoadingClass() {
    const $wrapper = $(`#${this.wrapperId}`);

    $wrapper.toggleClass(this.loadingClass);
  },

  ajaxContent(url) {
    this.isLoading = true;

    $.ajax(url, {
      dataType: `html`,
      success: response => {
        history.pushState({}, ``, url);
        this.changedPage = true;

        this.updatePage(response);
      },
      complete: () => {
        this.toggleWrapperLoadingClass();
        this.isLoading = false;

        if (this.activeSubTabs) {
          document.querySelector('.js-tabs').classList.add('sub-tabs-visible')
          this.activeSubTabs = null;
        }
      }
    });
  },

  onLinkClick(e) {
    const href = $(e.currentTarget).attr(`href`);

    if (e.currentTarget.classList.contains('js-tab')) {
      const matchingSubTabs = document.querySelector(`.js-sub-tabs[data-cat=${e.currentTarget.dataset.cat}]`);
      if (matchingSubTabs) {
        this.activeSubTabs = e.currentTarget.dataset.cat;
      }
    }

    e.preventDefault();

    if (this.isLoading) {
      return false;
    }

    $(`html, body`).animate({
      scrollTop: $(`#${this.wrapperId}`).offset().top
    }, 400);

    this.toggleWrapperLoadingClass();
    this.ajaxContent(href);
  },

  onSubTabClick(e) {
    const clicked = e.currentTarget;
    const subCat = clicked.dataset.subCat;
    let url;
    const pathArray = location.pathname.split('/').filter(x => x);

    if (pathArray.length < 3) {
      url = `${location.href}/${subCat}`;
      history.pushState({}, ``, url);
    } else {
      pathArray.splice(pathArray.length -1, 1)
      url = `${location.origin}/${pathArray.join('/')}/${subCat}`
      history.pushState({}, ``, url);
    }

    $(`.js-pub`).each((index, pub) => {
      $(pub).removeAttr('style');
    })

    $(`.js-sub-tab.is-active`).disabled = false;
    $(`.js-sub-tab.is-active`).removeClass('is-active');
    $(clicked).addClass('is-active');
    $(clicked).disabled= true;

    $(`.js-pub`).each((index, pub) => {
      const pubSubCats = pub.dataset.subCats.split(',');
      if (pubSubCats.find(el => el === subCat) === undefined) {
        pub.style.display = 'none';
      }
    });
  },

  addClickEvents() {
    utils.forEach(document.querySelectorAll(`.js-tab`), (index, tab) => {
      tab.addEventListener(`click`, this.onLinkClick.bind(this));
    });

    utils.forEach(document.querySelectorAll(`.ajax-content .js-pagination-link`), (index, button) => {
      button.addEventListener(`click`, this.onLinkClick.bind(this));
    });

    utils.forEach(document.querySelectorAll(`.js-sub-tab`), (index, subTab) => {
      subTab.addEventListener(`click`, this.onSubTabClick.bind(this));
    });
  },

  init() {
    if (!this.tabs.length) {
      return false;
    }

    this.addClickEvents();

    if (window.history && window.history.pushState) {
      window.addEventListener(`popstate`, e => {
        if (this.changedPage) {
          this.toggleWrapperLoadingClass();
          this.ajaxContent(location.href);
        }
      });
    }
  }
};
