import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const image = document.getElementById('impact-hero-bg');
const heroItems = document.querySelectorAll('.js-impact-hero-item');

export default function() {
  if (!image) return;
  const tl = gsap.timeline();
  tl.from(image, {
    opacity: 0,
    duration: 0.2
  })
  .from(heroItems, {
    opacity: 0,
    y: 10,
    stagger: 0.1,
    duration: 0.2
  })
}