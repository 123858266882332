import Player from '@vimeo/player';
import { utils } from './utils';

const modal = {
  els: document.querySelectorAll(`.js-modal`),
  elsMp4: document.querySelectorAll(`.js-modal-mp4`),
  playButtons: document.querySelectorAll(`.js-modal-open`),
  playMp4Buttons: document.querySelectorAll(`.js-modal-open-mp4`),
  videoPlayer: document.getElementById(`hero-video-player`),
  videoPlayerEls: document.querySelectorAll(`.js-video-player`),
  videoPlayers: [],
  closeEls: document.querySelectorAll(`.js-modal-close`),
  closeElsMp4: document.querySelectorAll(`.js-modal-close-mp4`),
  openClass: `modal-is-open`,

  open(index, e) {
    if (this.videoPlayerEls[index].hasChildNodes()) {
      e.preventDefault();
      this.els[index].classList.add(this.openClass);
      this.videoPlayers[index].play();
    }
  },

  openMp4(index) {
    this.elsMp4[index].classList.add(this.openClass);
    this.videoPlayer.play();
  },

  close(index) {
    utils.forEach(this.els, (index, el) => {
      el.classList.remove(this.openClass);
    });

    this.playButtons[index].focus();
    this.videoPlayers[index].pause();
  },

  closeMp4(index) {
    utils.forEach(this.elsMp4, (index, el) => {
      el.classList.remove(this.openClass);
    });

    this.playMp4Buttons[0].focus();
    this.videoPlayer.pause();
  },

  init() {
    if (!this.els) {
      return false;
    }

    // Vimeo video embeds
    utils.forEach(this.videoPlayerEls, (index, videoEl) => {
      const videoId = videoEl.getAttribute(`id`);

      this.videoPlayers.push(new Player(videoId));
      this.playButtons[index].addEventListener(`click`, this.open.bind(this, index));
    });

    // mp4 video embeds
    utils.forEach(this.playMp4Buttons, (index, playMp4Button) => {
      playMp4Button.addEventListener(`click`, this.openMp4.bind(this, index));
    });

    // close Vimeo embeds
    utils.forEach(this.closeEls, (index, closeEl) => {
      closeEl.addEventListener(`click`, this.close.bind(this, index));
    });

    // close MP4 embeds
    utils.forEach(this.closeElsMp4, (index, closeEl) => {
      closeEl.addEventListener(`click`, this.closeMp4.bind(this, index));
    });

    // allow esc key to close modal
    // document.addEventListener(`keyup`, e => {
    //   if (document.body.classList.contains(this.openClass) && e.keyCode == 27) {
    //     this.close();
    //   }
    // });
  }
};

export { modal };
