import { utils } from './utils';

const form = {
  proBonoForm: document.getElementById('pro-bono-form'),
  newsletterForm: document.querySelector(`.js-newsletter-form`),
  btn: document.querySelector('.js-form-toggle'),
  footer: document.getElementById(`footer`),
  checkboxes: document.querySelectorAll(`.js-checkbox`),
  allNewslettersCheckbox: document.getElementById(`all-newsletters`),
  allNewslettersClass: `all-newsletters`,
  newsletterError: document.querySelector(`.js-signup-error`),
  newsletterSubmit: document.querySelector(`.js-signup-submit`),

  toggle(e) {
    e.preventDefault();

    this.proBonoForm.classList.toggle(`is-active`);
  },

  checkAllBoxes(check) {
    utils.forEach(this.checkboxes, (index, checkbox) => {
      if (checkbox != this.allNewslettersCheckbox) {
        checkbox.checked = check;
      }
    });
  },

  checkForActiveCheckboxes() {
    let checkedOptions = 0;

    utils.forEach(this.checkboxes, (index, checkbox) => {
      if (checkbox.checked) {
        checkedOptions++;
      }
    });

    if (checkedOptions > 0) {
      this.newsletterError.classList.remove(`is-active`);
      return true;
    } else {
      this.newsletterError.classList.add(`is-active`);
      return false;
    }
  },

  handleCheckboxClick(checkbox, e) {
    const isChecked = checkbox.checked;

    if (checkbox === this.allNewslettersCheckbox) {
      this.checkAllBoxes(isChecked);
    } else {
      if (!isChecked) {
        this.allNewslettersCheckbox.checked = false;
      }
    }

    this.checkForActiveCheckboxes();
  },

  init() {
    if (this.btn) {
      this.btn.addEventListener(`click`, this.toggle.bind(this));
    }

    utils.forEach(this.checkboxes, (index, checkbox) => {
      checkbox.addEventListener(`change`, this.handleCheckboxClick.bind(this, checkbox));
    });

    if (this.newsletterSubmit) {
      this.newsletterSubmit.addEventListener(`click`, (e) => {

        if (!this.checkForActiveCheckboxes()) {
          e.preventDefault();
        } else {
          for (let i = 0; i < this.checkboxes.length; i++) {
            if (this.checkboxes[i].id != 'all-newsletters') {
              const checkbox = this.checkboxes[i];
              const matchHidden = document.querySelector(`#${checkbox.id}-hidden`);

              if (checkbox.checked) {
                matchHidden.disabled = true;
              }
            }
          }

          return true;
        }
      });
    }
  }
};

window.recaptchaCallback = function() {
  var signupSubmit = document.querySelector('.js-signup-submit');
  signupSubmit.removeAttribute('disabled');
};

export { form };
