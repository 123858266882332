import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const activeClass = 'link-is-active';
const navLinks = Array.from(document.querySelectorAll('.js-impact-nav-link'));
const textLinks = Array.from(document.querySelectorAll('.js-impact-text-section a[href^="#"]'));

let lastTimeout;

function initObserver(section) {
  const observer = new IntersectionObserver(function(observed) {
    const obItem = observed[0];
    const matchLink = navLinks.find(link => link.href.includes(obItem.target.id));
    if (!matchLink) return;
    if (obItem.isIntersecting) {
      // Do some debouncing so if a user scrolls fast we don't briefly highlight a passing section
      if (lastTimeout) clearTimeout(lastTimeout)
      lastTimeout = setTimeout(function() {
        // Check if another link has active class so you don't have two links highlighted at the same time
        const currentActive = document.querySelector(`.${activeClass}`);
        if (currentActive) {
          currentActive.classList.remove(activeClass);
        }
        matchLink.classList.add(activeClass);
      }, 500);  
    } else {
      matchLink.classList.remove(activeClass);
    }
  }, {
    rootMargin: `-100px 0px -100px 0px`,
  });

  observer.observe(section);
}

function initLink(link, isNavLink = false) {
  const selector = link.getAttribute('href');
  const section = document.querySelector(selector);
  if (!section) return;
  // Add click event listener to scroll to matching section
  link.addEventListener('click', function(e) {
    e.preventDefault();
    gsap.to(window, {
      scrollTo: section,
      duration: 0.5,
      ease: 'none',
    });
  });
  // If it's a nav link then observe matching section and highlight corresponding nav link
  if (!isNavLink) return;
  initObserver(section);
}

export default function() {
  navLinks.forEach(link => initLink(link, true));
  textLinks.forEach(link => initLink(link));
}
