import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { htmlLegendPlugin } from './chart-legend';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const black = '#272726';
Chart.defaults.font.family = 'Sofia W01 Bold1446843';
Chart.defaults.font.size = 18;
Chart.defaults.font.weight = 400;
Chart.defaults.color = black;
Chart.defaults.maintainAspectRatio = false;
Chart.defaults.plugins.legend.display = false;
Chart.defaults.plugins.tooltip.enabled = false;
Chart.defaults.elements.point.radius = 8;
Chart.defaults.elements.point.hoverRadius = 10;
Chart.defaults.elements.point.borderWidth = 0;
Chart.defaults.elements.line.borderColor = black;
Chart.defaults.elements.line.borderWidth = 2;
Chart.defaults.plugins.datalabels = {
  align: 'top',
  padding: {
    top: 6
  },
  anchor: 'end',
  color: black,
  font: {
    size: (() => {
      const width = window.innerWidth;
      if (width <= 375) {
        return 8;  
      } else if (width > 375 && width <= 550) {
        return 12;
      } else if (width > 550 && width <= 768) {
        return 16;
      } else {
        return 20;
      }
    })()
  }
}

const xScale = {
  grid: {
    display: false,
    drawTicks: false,
    borderWidth: 2,
    borderColor: black
  },
  ticks: {
    padding: 20
  }
}

const yGrid = {
  drawBorder: false,
  drawTicks: false,
  lineWidth: 2,
  offset: false
}

// console.log(Chart.defaults);

const charts = document.querySelectorAll('.js-chart');

function getMaxY(param) {
  // This seems to get called with different contexts: https://www.chartjs.org/docs/latest/general/options.html#scriptable-options
  if (param.type !== 'chart') return;
  const parent = param.chart.canvas.closest('.js-chart');
  const maxY = parseInt(parent.dataset.maxY);
  return maxY ? maxY : null;
}

function initChart(chart) {
  const type = chart.dataset.chartType;
  const dataObject = JSON.parse(chart.dataset.chartData);
  const isGrouped = Boolean(chart.dataset.chartGrouped);
  const showY = Boolean(chart.dataset.showY);
  const yUnit = chart.dataset.yUnit;
  const yUnitPos = chart.dataset.yUnitPos;
  const canvas = chart.querySelector('canvas');
  const legend = chart.querySelector('[id$="label-legend-container"]');
  let data;
  let plugins = [];
  let optionsPlugins = {};
  
  if (legend) {
    optionsPlugins = { 
      htmlLegend: {
        containerID: legend.id
      } 
    }
  }

  plugins.push(ChartDataLabels);

  if (legend) {
    plugins.push(htmlLegendPlugin)
  }

  if (isGrouped) {
    const datasets = dataObject.dataArray.map(dataset => {
      return {
        label: dataset.label,
        data: dataset.data.split(',').map(value => parseInt(value)),
        backgroundColor: dataset.color
      }      
    });
    // For longer labels Chart.js requires linebreaks to be expressed as an array
    const groupLabels = dataObject.groupLabels.map(string => string.split('\n'));
    data = {
      labels: groupLabels,
      datasets: datasets
    }
  } else {
    data = {
      // For longer labels Chart.js requires linebreaks to be expressed as an array
      labels: dataObject.dataArray.map(row => row.label.split('\n')),
      datasets: [
        {
          data: dataObject.dataArray.map(row => parseInt(row.data)),
          backgroundColor: dataObject.dataArray.map(row => row.color),
        }
      ]
    }
  }

  ScrollTrigger.create({
    trigger: chart.parentElement,
    start: 'top center',
    once: true,
    onEnter: () => {
      new Chart(canvas, {
        type: type,
        data: data,
        options: {
          layout: {
            padding: {
              top: legend ? 40 : 1
            }
          },
          scales: {
            y: {
              min: 0,
              max: getMaxY,
              ticks: {
                callback: function(value) {
                  const text = yUnitPos === 'before' ? `${yUnit}${value}` : `${value}${yUnit}`;
                  return text;
                },
                padding: 20,
                crossAlign: 'near',
                display: showY
              },
              grid: yGrid
            },
            x: xScale
          },
          plugins: optionsPlugins,
        },
        plugins: plugins,
      });
    }
  });
}

export default function() {
  charts.forEach(chart => initChart(chart));
}